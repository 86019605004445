import React from "react"
import { StaticImage } from 'gatsby-plugin-image'

// styles
const pageStyles = {
  color: "#232129",
  padding: "5vw",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


const AboutPage = () => (
  <main style={pageStyles}>
<title>Beunhaas & Beunhaas Productions</title>
    <h1>Beunhaas & Beunhaas Productions</h1>
    <p>Alles voor uw producties</p>
    <p>Voor dramatische zwart-wit fotos.</p>
    <StaticImage src="../images/OHN_BG.jpg" alt="Wow!" placeholder="blurred"></StaticImage>
    <p>Wij gaan voorzichtig met ons personeel om! Up to date met alle ISO/NEN standaarden voor transport.</p>
    <StaticImage src="../images/personeel.jpg" alt="Wow!" placeholder="blurred"></StaticImage>
    <p>Sindskort ook beschikbaar: Pyromanen! Hieronder ziet u een van onze <s>stagiairs</s>medewerkers met een hightech pyro-dorito:</p>
    <StaticImage src="../images/vuurdorito.jpg" alt="Wow!" placeholder="blurred"></StaticImage>
  </main>
)
export default AboutPage